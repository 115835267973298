/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Input,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  Text,
  Heading,
  Center,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link } from 'gatsby';
import { PrimaryButton } from '../../buttons';
import './signin.css';
import { signIn } from '../../../state/user/userSlice';
import { useAppDispatch } from '../../../state/createStore';
import 'react-toastify/dist/ReactToastify.css';

interface SignInInput {
  username: string;
  password: string;
}

const SignInForm = (): JSX.Element => {
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = async (data: SignInInput) => {
    setLoading(true);
    try {
      await dispatch(signIn(data)).then(unwrapResult);
      setLoading(false);
    } catch (error) {
      toast.error(`Failed to Sign in ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <Center>
      <Flex
        w="100%"
        justify={['space-around', 'space-evenly']}
        direction={['column-reverse', 'row']}
        marginY={['15vh', '21vh']}
      >
        <Box
          rounded="md"
          boxShadow="xl"
          border="1px"
          borderColor="gray.200"
          bgColor="white"
          padding="20px"
          height="auto"
          maxH="450px"
        >
          <form action="submit" onSubmit={handleSubmit(onSubmit)}>
            <Stack marginBottom="10px">
              <Heading>Login</Heading>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="username"
                  height="50px"
                  {...register('username', {
                    required: true,
                  })}
                  placeholder="Username"
                />
                <FormHelperText color="red.500">
                  {errors.username && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="password"
                  height="50px"
                  {...register('password', {
                    required: true,
                  })}
                  type="password"
                  placeholder="Password"
                />
                <Link className="links" to="/forgot-password">
                  Forgot your password?
                </Link>
                <FormHelperText color="red.500">
                  {errors.password && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
              <PrimaryButton
                paddingY="25px"
                isLoading={isLoading}
                type="submit"
              >
                Sign In
              </PrimaryButton>
            </Stack>
            <Stack>
              <Flex direction="column">
                <Text>Don't have an account? </Text>{' '}
                <Link className="links" to="/signup">
                  {' '}
                  Sign up here
                </Link>
              </Flex>
            </Stack>
          </form>
        </Box>
        <Box padding="20px" height="auto" maxH="400px">
          <Heading color="#fff" fontSize={['28pt', '60pt']}>
            Welcome Back
          </Heading>
          <Text fontSize={['15pt', '20pt']}>
            Login to access your dashboard
          </Text>
        </Box>
      </Flex>
      <ToastContainer
        theme="colored"
        autoClose={3000}
        position="bottom-center"
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
    </Center>
  );
};

export default SignInForm;
