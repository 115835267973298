import * as React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import SEO from '../components/seo/seo';
import './css/signin.css';
import SignInForm from '../components/forms/signin/signin';
import Navbar from '../components/nav/navbar/navbar';
import Footer from '../components/footer/footer';
import 'react-toastify/dist/ReactToastify.css';

const SignInPage = (): JSX.Element => {
  const [isClient, setClient] = React.useState(false);
  React.useEffect(() => {
    setClient(true);
  }, []);
  if (!isClient) return null;

  return (
    <>
      <Box
        overflow="clip"
        bgImage="linear-gradient(rgba(79, 193, 255, 0.8),rgba(79, 193, 255, 0.8)) , url('https://openscreen-web-assets-prod.s3.amazonaws.com/background.png')"
      >
        <Navbar />
        <Box minH="100vh" className="inner-container">
          <SEO title="Sign In" />
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: 'spring',
              mass: 0.35,
              stiffness: 75,
              duration: 0.3,
            }}
          >
            <SignInForm />
          </motion.div>
        </Box>
      </Box>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default SignInPage;
